import React, { useState } from 'react';
import { Label } from 'beautiful-react-ui';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import useStyles from './DialogformComponentStyles';

export const RadiobuttonComponent = ({ modelelement, handleInput, nodeID, avain }) => {

  const [value, setValue] = useState((modelelement.data === "") ? modelelement.default : modelelement.data)
  /*
    useEffect(() => {
      setValue(modelelement.data)
    }, [modelelement.data])
  */
  const classes = useStyles();

  return (
    <li key={avain} style={{ padding: "10px" }}>
      <FormControl>
        <Label htmlFor="field-id" className={classes.root} >{modelelement.label}</Label>
        <RadioGroup value={value}
          onChange={(e) => {
            setValue(e.target.value);
            handleInput(nodeID, modelelement.id, e)
          }}>
          {modelelement.values.map((arvo, index) => {
            return <FormControlLabel
              value={arvo}
              control={<Radio />}
              label={arvo}
              key={avain + index}
            />
          })}
        </RadioGroup>
      </FormControl>
    </li>
  )
}