import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from 'date-fns/locale/fi';
import { FormGroup, Label } from 'beautiful-react-ui';
import "react-datepicker/dist/react-datepicker.css";
import useStyles from './DialogformComponentStyles';

registerLocale("fi", fi);

export const DatePickerComponent = ({ modelelement, handleInput, nodeID, type, avain }) => {
  //mikäli päiväys ei ole kelvollinen päivä käytä kuluvaa päivää
  var paivays = (datestring) => {
    let date = new Date(datestring);
    if (isNaN(date)) {
      return new Date()
    } else
      return date;
  }

  const [value, setValue] = useState(paivays(modelelement.data))
  /*
      useEffect(() => {
  //        modelelement.data = value;
          setValue(paivays(modelelement.data));
      }, [modelelement.data])
  */
  const classes = useStyles();

  if (type === 'dateonly') {
    return (<li key={avain} style={{ padding: "10px" }}>
      <FormGroup orientation="vertical">
        <Label htmlFor="field-id" className={classes.root}>{modelelement.label}</Label>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          margin="normal"
          id="date-picker-inline"
          showWeekNumbers
          locale={"fi"}
          selected={value}
          onChange={(newdate) => {
            setValue(newdate)
            handleInput(nodeID, modelelement.id, newdate);
          }
          }
        />
      </FormGroup>
    </li>
    )
  }
  else {
    return (<li key={avain} style={{ padding: "10px" }}>
      <FormGroup orientation="vertical">
        <Label htmlFor="field-id" className={classes.root}>{modelelement.label}</Label>
        <DatePicker
          showWeekNumbers
          dateFormat="dd.MM.yyyy HH.mm"
          margin="normal"
          id="date-time-picker"
          showTimeSelect
          timeIntervals="15"
          onChange={(newdate) => {
            setValue(newdate)
            handleInput(nodeID, modelelement.id, newdate);
          }
          }
          locale={"fi"}
          selected={value}
          value={value}
        />
      </FormGroup>
    </li>
    )
  }
}
