import React, { useContext, useEffect, useState, useCallback } from 'react'
import ostContext from './ostContext'
import App from '../App'
import { Navigate } from 'react-router-dom'

export function ProtectedRoute() {

  const context = useContext(ostContext);
  const [userID, setUserID] = useState(context.userID);

  const fetchMyAPI = useCallback(async () => {
    let response = await fetch('/profile')
    response = await response.json()
    setUserID(response.userID)
    context.userID = userID
  }, [context, userID]) // if userID changes, useEffect will run again

  useEffect(() => {
    if (context.userID === '' || context.userID === undefined) {
      fetchMyAPI()
    }
  }, [fetchMyAPI, context.userID])

  return context.userID !== '' && context.userID !== undefined ? <App /> : <Navigate to='/signin' />
}

export default ProtectedRoute