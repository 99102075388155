import React, { useState } from "react"
import { TextField, List, ListItem, ListItemIcon, Checkbox, Button, Dialog, DialogContentText, DialogContent, DialogTitle, DialogActions, ListItemText, ListItemButton } from "@mui/material"
import _ from "lodash";
import { useEffect } from "react";

export const InviteDialog = ({ open, onClose, userList, listChanged }) => {

    const [ textFieldValue, setTextFieldValue ] = useState ('');
    const [ userlist, setUserlist ] = React.useState(userList)
    const [ checked, setChecked ] = React.useState([0]);

    useEffect(() => {
        listChanged(userlist);
    }, [userlist, listChanged]);

    function handleInviteButtonClick (e) {
        if (textFieldValue.length > 0) {
            setUserlist(userlist => [...userlist, textFieldValue]);
            setTextFieldValue('');
        }
    } 

    function handleRemoveSelectedButtonClick (e) {
        setUserlist(_.difference(userlist, checked));
        setChecked ([0]);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    if (_.isUndefined(userlist)) {
        return <></>;
    } else {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold'}}>
                    Invite people to collaborate with you
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Invite people to edit this document by entering their utu email.
                    </DialogContentText>
                    <div style={{ display: "flex", alignItems: "center", paddingTop: '20px' }}>
                        <TextField sx={{ width: '75%' }} value={textFieldValue} onChange={(e) => setTextFieldValue(e.target.value)} />
                        <Button style={{ marginLeft: '20px' }} onClick={handleInviteButtonClick}>Invite</Button>
                    </div>
                    <DialogContentText style={{ paddingTop: '20px' }}>
                        Invited users
                    </DialogContentText>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {userlist.map((element, index) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton role={undefined} onClick={handleToggle(element)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(element) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }} />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={element} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    <DialogActions container justify='flex-end'>
                        <Button color={'warning'} onClick={handleRemoveSelectedButtonClick}>Remove selected</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}