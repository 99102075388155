import React, { Component } from 'react';
import { Button } from 'beautiful-react-ui';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { TextfieldComponent } from './dialogcomponents/TextfieldComponent';
import { TextareaComponent } from './dialogcomponents/TextareaComponent';
import { DatePickerComponent } from './dialogcomponents/DatePickerComponent';
import { RadiobuttonComponent } from './dialogcomponents/RadiobuttonComponent';
import { CheckboxComponent } from './dialogcomponents/CheckboxComponent';
class DynamicModal extends Component{

	handleChange = (element, e) => {
		element.data = e.target.value;
	};

	handleCheckboxChange = (element, e) => {
		element.data === "checked" ? element.data = "" : element.data = "checked";
	};

	render () {
		if (this.props.model !== undefined) { 
			return (
				<Dialog open={this.props.show} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
					<DialogContent>
						<DialogContentText>
							Edit module information
						</DialogContentText>
						<ul key='uniqueKey' style={{ listStyleType: "none" ,paddingRight: '40px'}}>
							{this.props.model.map((modelelement, i) => {
								switch (modelelement.type) {
									case "textfield":
										return <TextfieldComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} avain={modelelement.id}/>
									case "textarea":
										return <TextareaComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} avain={modelelement.id}/>
									case "datepicker":
										return <DatePickerComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} type={'dateonly'} avain={modelelement.id} />
									case "datetimepicker":
										return <DatePickerComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} avain={modelelement.id} />
									case "radiobutton":
										return <RadiobuttonComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} avain={modelelement.id} />
									case "checkbox":
										return <CheckboxComponent modelelement={modelelement} handleInput={this.props.handleInput} nodeID={this.props.nodeID} avain={modelelement.id} />
									default:
										return "";
								}
							})}
						</ul>
                        <p style={{ opacity: '0.5', textAlign: 'center', fontSize: 'small' }}>If first character of field is ! field will not be exported.</p>
                        <p style={{ opacity: '0.5', textAlign: 'center', fontSize: 'small' }}>Markdown notation can be used for formatting a field content.</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.handleClose}>Close</Button>
					</DialogActions>
				</Dialog>
			);

		} else {
			return "";
		}
	}
}

export default DynamicModal;

