import React from 'react';
import { Grid } from '@material-ui/core';
import CourseCard from './CourseCard';

class CoursePalette extends React.Component {

  state = {
    cards: [],
    onClickEvent: '',
  }

  constructor(props) {
    super(props);
    this.setState({ cards: props.cards, onClickEvent: props.onClickEvent });
  }

  componentDidMount() {
    fetch('/api/v1/components')
      .then(res => res.json())
      .then((data) => {
        this.setState({ cards: data })
      })
      .catch(console.log)
  }



  render() {

    const { onClickEvent } = this.props;

    return (
      <Grid container
        spacing={2}
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start">
        {this.state.cards.map((card, i) => (
          <CourseCard key={i} id={card.id} objectIdentifier={card.objectIdentifier} componentData={card} onClickEvent={onClickEvent} />
        ))}
      </Grid>
    )
  }
}

export default CoursePalette;

