import React, { useState } from 'react';
import Avatar from 'react-nice-avatar';
import '../App.css';

const AwarenessList = (props) => {

  const [list, setList] = useState('')
  setList(props.userlist)
  //    useEffect(() => {setList(props.userlist)}, [props.userlist]);

  return (
    <>
      <div className='AvatarListDiv' >
        <ul className='AvatarList'>
          {list.map((user) => {
            return <li key={user.id} className='Avatar'><Avatar style={{ width: '2rem', height: '2rem' }} {...user.avatar} />{user.name}</li>
          })
          }
        </ul>
      </div>
    </>
  )
  //  }
}

export default AwarenessList;
