import React, { useState } from 'react';
//import { Label } from 'beautiful-react-ui';
import { Checkbox, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import useStyles from './DialogformComponentStyles';

export const CheckboxComponent = ({ modelelement, handleInput, nodeID, avain }) => {

  const [checked, setChecked] = useState(modelelement.data);
  /*
    useEffect(() => {
      setChecked(modelelement.data);
    }, [modelelement.data])
  */
  const classes = useStyles();

  return (
    <li key={avain} style={{ padding: "10px" }}>
      <FormGroup orientation="horizontal">
        <FormLabel htmlFor="field-id" className={classes.root}>{modelelement.label}</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              name={modelelement.name}
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              onInput={(e) => handleInput(nodeID, modelelement.id, e)}
            />
          }
          label={modelelement.label} />
      </FormGroup>
    </li>
  )
}