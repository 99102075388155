import React, { useState, } from 'react'

import { Divider, TextField } from '@material-ui/core'
import { Button, Icon } from 'beautiful-react-ui'

import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import DynamicModal from './DynamicModal'
import Filter1Icon from '@mui/icons-material/Filter1'

export const CustomNode = ({ id, outputs, inputs, data, content }) => {
  const [visibility, setVisibility] = useState(false)
  const [title, setTitle] = useState(data.componentData.title)
  const [description, setDescription] = useState(data.componentData.description)
  const [raised, setRaised] = useState(false)
  const [firstNode, setFirstNode] = useState(data.firstNode)

  const useStyles = makeStyles({
    root: {
      minWidth: 200,
      maxWidth: 200,
      height: 132,
      background: '#f8f9fa',
      transition: 'transform 0.15s ease-in-out',
      margin: 0,
      paddingBottom: 0
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 5,
      height: 20
    },
    description: {
      paddingTop: 5,
      fontSize: 14,
      paddingBottom: 0,
      height: 35,
      color: 'textSecondary'
    },
    cardHovered: {
      transform: 'scale3d(1.05, 1.05, 1)'
    },
    popover: {
      visibility: 'hidden',
      padding: 0,
      top: '0',
      right: '0',
      position: 'absolute'
    },
    popoverVisible: {
      visibility: 'visible'
    },
    portBox: {
      bottom: '0',
      left: '0',
      width: '100%',
      position: 'absolute',
      marginBottom: '2px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })

  const classes = useStyles()

  const showModal = (data) => {
    setVisibility(true)
  }

  const hideModal = () => {
    setVisibility(false)
  }

  const dialogformButton = (
    data.componentData.dialogform !== undefined ? <Button size='small' color='primary' icon={<Icon name='pen-alt' />} onClick={() => showModal(data)} /> : ''
  )

  const firstNodeButton = (
    firstNode === true
      ? (
        <Button
          size='small' icon={<Filter1Icon style={{ fontSize: 'small', fill: 'red' }} />} onClick={(e) => {
            setFirstNode(false)
            data.onSelectFirstButtonClick(e, id)
          }}
        />
      )
      : (
        <Button
          size='small' icon={<Filter1Icon style={{ fontSize: 'small', fill: 'black' }} />} onClick={(e) => {
            setFirstNode(true)
            data.onSelectFirstButtonClick(e, id)
          }}
        />
      )
  )
  /*
    useEffect(() => {
      setTitle(data.componentData.title)
      setDescription(data.componentData.description)
      setFirstNode(data.firstNode)
    }, [data.componentData.title, data.componentData.description, data.firstNode])
  */
  return (
    <Card
      className={classes.root}
      classes={{ root: raised ? classes.cardHovered : '' }}
      onMouseOver={(e) => { setRaised(true) }}
      onMouseOut={(e) => { setRaised(false) }}
      raised={raised}
    >
      <CardActions className={classes.popover} classes={{ root: raised ? classes.popoverVisible : '' }}>
        {dialogformButton}
        {firstNodeButton}
        <Button size='small' color='warning' icon={<Icon name='trash' />} onClick={() => data.onDeleteButtonClick(id)} />
      </CardActions>
      <CardContent>
        <TextField
          size='small'
          className={classes.title}
          InputProps={{ disableUnderline: true, classes: { input: classes.title } }}
          onInput={(e) => data.onNodeTextChange(e, id, 'title')}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <Divider variant='middle' />
        <TextField
          size='small'
          className={classes.description}
          InputProps={{ disableUnderline: true, classes: { input: classes.description } }}
          onInput={(e) => data.onNodeTextChange(e, id, 'description')}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </CardContent>
      <Box className={classes.portBox}>
        {outputs.map((port) => React.cloneElement(port, {},
          <div style={{ backgroundColor: '#fefefe', pointerEvents: 'none', display: 'flex', justifyContent: 'space-between' }}>
            <ArrowBackIosIcon style={{ pointerEvents: 'none' }} />
            <div style={{ pointerEvents: 'none', paddingTop: '3px' }}>Yhdistä vetämällä</div>
            <ArrowForwardIosIcon style={{ pointerEvents: 'none' }} />
          </div>
        ))}
      </Box>
      <DynamicModal show={visibility} handleClose={hideModal} model={data.componentData.dialogform} nodeID={id} handleInput={data.handleInput} />
    </Card>
  )
}

// {inputs.map((port) => React.cloneElement(port, {}, <ArrowBackIosIcon style={{ pointerEvents: 'none' }} />))}
