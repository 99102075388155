import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	root: {
		minWidth: 200,
		maxWidth: 200,
		height: 130,
		background: "#f8f9fa",
		transition: "transform 0.15s ease-in-out",
		margin: 5,
		paddingBottom: 0,
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		paddingBottom: 5,
	},
	description: {
		paddingTop: 5,
		fontSize: 14,
	},
	cardHovered: {
		transform: "scale3d(1.05, 1.05, 1)"
	},
});

export default function CourseCard ( props ) {

	const classes = useStyles();

	const [state, setState] = useState({
		raised: false,
		shadow: 1,
	})

	return (
		<Card className={classes.root} classes={{ root: state.raised ? classes.cardHovered : "" }}
			onMouseOver={() => setState({ raised: true, shadow:2 })}
			onMouseOut={() => setState({ raised: false, shadow: 1 })}
			onClick={() => props.onClickEvent ( props ) }
 			raised={state.raised} zdepth={state.shadow}>
			<CardActionArea>
				<CardContent>
					<Typography className={classes.title} variant="h5" component="h2">
						{props.componentData.title}
					</Typography>
					<Divider variat="middle" />
					<Typography className={classes.description} color="textSecondary">
						{props.componentData.description}
					</Typography>
				</CardContent>
				<CardActions>
				</CardActions>
			</CardActionArea>
		</Card>
	);
}