import React, { useState, useContext } from 'react';
import 'beautiful-react-diagrams/styles.css';
import './App.css';
import { createSchema, useSchema } from 'beautiful-react-diagrams';
import { Divider, Grid } from '@material-ui/core';
import CoursePalette from './components/CoursePalette.js';
import CourseList from './components/CourseList';
import { CustomNode } from './components/CustomNode';
import Diagram from 'beautiful-react-diagrams';
import * as underscore from 'underscore';
import _ from 'lodash';
import * as Y from 'yjs';
import { isUndefined } from 'underscore';
import AwarenessList from './components/AwarenessList';
import uniqid from 'uniqid';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InviteDialog } from './components/Invite';
import uuid from 'react-uuid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import axios from 'axios';
import { LeveldbPersistence } from 'y-leveldb';
import ostContext from './components/ostContext';
import { WebsocketProvider } from 'y-websocket';
import Tree from './tree.js';
import { saveAs } from 'file-saver';
import { genConfig, AvatarConfig } from 'react-nice-avatar';

let lasttime = (Date.now() / 1000);

const App = () => {
  //  let defaultUser = 'anyuser@utu.fi';
  let currentUser = "";
  const emptySchema = createSchema({
    nodes: [],
    links: []
  });
  const [title, setTitle] = useState('new course');
  const [description, setDescription] = useState('course description');
  const [schema, { onChange, removeNode }] = useSchema(emptySchema);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  //const server = 'wss://ostdev.utu.fi/ws';
  const server = 'wss://ostdev.utu.fi:1234';
  const documentName = 'my-doc';
  const persistence = new LeveldbPersistence('./storage-location')

  const context = useContext(ostContext);

  /*
  Y.doc:ssa on sisällä seuraavat arrayt:
  SharedNodes sisältää diagrammin nodet. 
  SharedLinks sisältää diagrammin linkit.
  SharedDocumentMetadata sisältää avain - arvo parit:
      Avain               Arvo
      ==================================
      title:              dokumentin nimiSähkö
      description:        dokumentin kuvaus
      userEmail:          SAML:lta saatu käyttäjän sähköpostiosoite. 
      room:               yjs:n huoneen nimi
      allowed_users:      käyttäjät, joilla on dokumentin katselu ja muokkaus oikeudet.
  */

  function createNewProvider(doc, roomname) {
    if (context.provider) context.provider.destroy();
    let wsProvider = new WebsocketProvider(server, roomname, doc, { resyncInterval: 20000 });
    context.provider = wsProvider;
    context.userlist = [];

    wsProvider.on('status', event => {
      console.log(event.status) // logs "connected" or "disconnected"
    })

    wsProvider.on('sync', (synced) => {
      //console.log (synced);
    })
  }

  window.addEventListener('onbeforeunload', function () {
    window.removeEventListener('onbeforeunload');
    context.provider.destroy();
  });

  function createNewWebSocketProvider(roomname) {
    context.doc1 = new Y.Doc()
    context.doc2 = new Y.Doc()
    createNewProvider(context.doc2, roomname);

    flushNodes();
    flushLinks();
    context.sharedNodes = context.doc1.getArray('nodes');
    context.sharedLinks = context.doc1.getArray('links');

    const allowed_users = new Y.Array()
    allowed_users.push(currentUser);
    context.sharedDocumentMetaData = context.doc1.getMap('metadata');
    context.sharedDocumentMetaData.set('room', roomname);
    context.sharedDocumentMetaData.set('allowed_users', new Array(currentUser));

    // Tässä ongelmia netistä ladattaessa. Description ei päivity oikein ruudulle vaan saa aina arvon 'new course'
    // 
    setTitle(context.sharedDocumentMetaData.title ? context.sharedDocumentMetaData.title : 'new course');
    setDescription(context.sharedDocumentMetaData.description ? context.sharedDocumentMetaData.description : 'course description');
    context.sharedDocumentMetaData.set('title', context.sharedDocumentMetaData.title ? context.sharedDocumentMetaData.title : 'new course');
    context.sharedDocumentMetaData.set('description', context.sharedDocumentMetaData.description ? context.sharedDocumentMetaData.description : 'course description');

    context.awareness = context.provider.awareness;

    context.awareness.setLocalStateField('user', {
      // Define a print name that should be displayed
      name: currentUser.substring(0, currentUser.lastIndexOf("@")),
      // Define a color that should be associated to the user:
      color: '#ffb61e' // should be a hex color
    })

    context.doc1.on('update', update => {
      // const stateVector1 = Y.encodeStateVector(doc1)
      const stateVector2 = Y.encodeStateVector(context.doc2)
      const diff1 = Y.encodeStateAsUpdate(context.doc1, stateVector2)
      //  const diff2 = Y.encodeStateAsUpdate(doc2, stateVector1)
      Y.applyUpdate(context.doc2, diff1)
    })

    context.doc2.on('update', update => {
      Y.applyUpdate(context.doc1, update);
    })

    context.awareness.on('change', (muutokset) => {

      if (isUndefined(context.userlist)) {
        context.userlist = [];
      }

      let nextUserList = [];
      let states = context.awareness?.getStates();
      if (!isUndefined(states)) {
        for (const [key, element] of states.entries()) {
          let elementlist = context.userlist.filter(user => user['id'] === key);
          if (elementlist.length > 0) {
            nextUserList.push(_.first(elementlist));
          } else {
            nextUserList.push({
              name: element.user.name,
              id: key,
              avatar: genConfig(AvatarConfig)
            })
          }
        }
      }
      context.userlist = nextUserList;
    })

    persistence.storeUpdate(documentName, Y.encodeStateAsUpdate(context.doc2))

    context.sharedNodes.observe(yarrayEvent => {
      sharedNodesChanged(yarrayEvent);
    })

    context.sharedLinks.observe(yarrayEvent => {
      sharedLinksChanged(yarrayEvent);
    })

    context.sharedDocumentMetaData.observe(ymapEvent => {
      sharedDocumentMetaDataChanged(ymapEvent)
    })
  }

  // This removes a node on editor. 
  const deleteNode = (id) => {
    const nodeToRemove = schema.nodes.find(node => node.id === id);
    if (nodeToRemove !== undefined) {
      removeNode(nodeToRemove);
      context.sharedNodes.forEach((node, index) => {
        if (node.id === id) {
          context.sharedNodes.delete(index, 1);
        }
      })
      onChange(schema);
    }
  }

  //This flushes all nodes on editor schema.
  function flushNodes() {
    var nodelist = [];
    if (!_.isUndefined(schema.nodes) && (schema.nodes.length > 0)) {
      schema.nodes.forEach((node, index) => {
        nodelist.push(node.id)
      });
      nodelist.forEach((nodeitem, index) => {
        deleteNode(nodeitem)
      })
      context.sharedNodes.delete(0, context.sharedNodes.length);
    }
  }

  function flushLinks() {
    schema.links.length = 0;
    context?.sharedLinks?.delete(0, context.sharedLinks.length);
  }

  function sharedLinksChanged(yarrayEvent) {
    for (const value of yarrayEvent.changes.delta) {
      for (const [avain, arvo] of Object.entries(value)) {
        if (avain === 'insert') {
          arvo.forEach(solmu => {
            let linknode = _.find(schema.links, function (o) { return o.input === solmu.input && o.output === solmu.output });
            if (isUndefined(linknode)) {
              schema.links.push(solmu)
              onChange(schema);
            }
          })
        } else if (avain === 'delete') {
          schema.links = Array.from(context.sharedLinks.toArray());
        }
      }
    }
  }

  function sharedNodesChanged(yarrayEvent) {
    schema.nodes = [];
    pushNodesToArray(context.sharedNodes);
    onChange(schema);
    // Ylläoleva koodi on kokeellinen versio allaolevasta. Erona on, että allaoleva koodi 
    // yrittää päivittää vain muutokset schemaan. Ylläoleva taas ottaa kaiken eventin mukana
    // tulevan datan talteen ja päivittää sen schemaan.
    /*  if (yarrayEvent instanceof Y.YArrayEvent) {
        for (const value of yarrayEvent.changes.delta) {
          for (const [avain, arvo] of Object.entries(value)) {
            if (avain === 'insert') {
              arvo.forEach(solmu => {
                let tulosvektori = new Array(...schema.nodes.filter(e => e.id === solmu.id));
                // Jos schema.nodes vektorista ei löydy solmua annetulla id:llä, lisätään solmu vektoriin
                if (tulosvektori.length === 0) {
                  pushNodesToArray(new Array(solmu));
                  onChange(schema)
                } else {
                  // Jos schema.nodes vektorissa on solmu annetulla id:llä, muutetaan sen sisältö 
                  // samaksi kuin sisääntulevassa datassa. Koska y.js on varsin avokätinen eventien 
                  // heittämisessä ja niitä tulee sekä lokaaleista, että etämuutoksista
                  // on tarpeen tarkistaa erikseen jokainen dataobjekti joka voi muuttua. 
                  let index = getNodeIndexFromArrayV2(schema.nodes, solmu)
                  if (index > -1) {
                    if (_.isEqual(schema.nodes[index].coordinates, solmu.coordinates) !== true) {
                      schema.nodes[index].coordinates = [solmu.coordinates[0], solmu.coordinates[1]];
                      onChange(schema)
                    }
                    if (_.isEqual(schema.nodes[index].data.componentData, solmu.data.componentData) !== true) {
                      _.merge(schema.nodes[index].data.componentData, solmu.data.componentData);
                    }
                  }
                }
              })
            } else if (avain === 'delete') {
              if (arvo > 0) {
                let ynodearray = context.sharedNodes.toArray();
                let nodestoremove = [];
                // Ensin filtteröidään schema.nodes arraystä ne solmut pois, jota y:ssä ei enää ole.
                schema.nodes = schema.nodes.filter(
                  (solmu) => {
                    let destroyNode = findNodeFromYArray(ynodearray, solmu.id) !== undefined;
                    if (!destroyNode) nodestoremove.push(solmu);
                    return destroyNode
                  });
                // seuraavaksi täsmäytetään BRD:n käsitys omista solmuistaan. Tämä poistaa myös 
                // linkit poistettujen solmujen väliltä.
                for (let solmu of nodestoremove) {
                  removeNode(solmu);
                }
                onChange(schema);
              }
            }
          }
        }
      }*/
  }

  function sharedDocumentMetaDataChanged(ymapEvent) {
    // Y heittää kerralla pahimmillaan satoja eventtejä. Jos jokainen lähetetään 
    // palvelimelle, se tukehtuu pyyntöihin. Siksi tässä on rajoitin, joka pitää
    // lähettämisessä 5 sekunnin tauon. Oikeudeiden muutosten on nollattava laskuri
    // eli asettamalla (lasttime = 0) mikäli kaikki muutokset halutaan toimitettavan 
    // palvelimelle
    let now = (Date.now() / 1000)

    if ((now - lasttime) > 5) {
      if (ymapEvent instanceof Y.YMapEvent) {
        let newTitle = context.sharedDocumentMetaData.get('title');
        let newDescription = context.sharedDocumentMetaData.get('description');
        setTitle(newTitle !== undefined ? newTitle : title);
        setDescription(newDescription !== undefined ? newDescription : description);
        let metaobject = {
          title: context.sharedDocumentMetaData.get('title'),
          description: context.sharedDocumentMetaData.get('description'),
          room: context.sharedDocumentMetaData.get('room'),
          allowed_users: context.sharedDocumentMetaData.get('allowed_users')
        }
        if (typeof metaobject.allowed_users !== 'undefined' && metaobject.room !== '') {
          axios.post('/api/v1/newcourse', metaobject);
          lasttime = now;
        }
      }
    }
  }

  // Koska y-array ei ole sama asia kuin JavaScript array, eikä sisällä samoja funktioita, 
  // on niiden käsittelyyn ja läpikäymiseen tehty omat funktiot

  function replaceItemInYArray(y_array, node, index) {
    let nodeclone = underscore.clone(node);
    nodeclone.coordinates = JSON.parse(JSON.stringify(node.coordinates));
    context.doc1.transact(() => {
      y_array.insert(index, new Array(nodeclone));
      y_array.delete(index + 1, 1);
    })
  }

  function getNodeIndexFromYArray(y_array, node) {
    for (const [index, value] of y_array.toJSON().entries()) {
      if (value.id === node.id) return index;
    }
    return -1;
  }
  /*
    function getNodeIndexFromArrayV2(array, node) {
      for (const [index, value] of array.entries()) {
        if (value.id === node.id) return index;
      }
      return -1;
    }
  */
  function findNodeFromYArray(y_array, id) {
    for (let node of y_array) {
      if (node.id === id) {
        return node;
      }
    }
    return undefined;
  }

  //tämä ajetaan kun nodeja ladataan. Lataus tehdään joko palvelimelta tai y.js:n päivittyessä.
  function pushNodesToArray(nodes) {
    nodes.forEach(node => {
      // Uuden noden tekeminen on välttämätöntä, koska callback kutsuja ei tallenneta tietokantaan.
      const nextNode = {
        id: node.id,
        content: node.content,
        coordinates: JSON.parse(JSON.stringify(node.coordinates)),
        render: CustomNode,
        data: {
          onClick: deleteNode,
          onDeleteButtonClick: deleteNode,
          onSelectFirstButtonClick: onSelectFirst,
          componentData: JSON.parse(JSON.stringify(node.data.componentData)),
          handleInput: handleTextfieldInput,
          onNodeTextChange: handleNodeTitleAndDescriptionChanges,
          firstNode: node.data.firstNode
        },
        inputs: node.inputs,
        outputs: node.outputs,
      };
      // Uusi node työnnetään suoraan vektoriin, koska Beautiful-React-Diagrams frameworkin 
      // lisäysrutiinin bugin takia se lisää nodet kahdesti.
      schema.nodes.push(nextNode)
    })
  }

  const addNewNode = (cardElementData) => {

    const { componentData } = cardElementData;

    createNewRoom();

    if (componentData.dialogform !== undefined) {
      componentData.dialogform.forEach(component => {
        component.id = uniqid();
      })
    }

    const nextNode = {
      // Satunnainen id on välttämätön. Muutoin saattaisi eri clienteissa muodostua sama id eri nodeille. 
      // Satunnaisluvun kanssa todenäköisyys on aika pieni, käytännössä olematon 
      id: uniqid('node-'),
      content: componentData.title,
      coordinates: [
        schema.nodes.length !== 0 ? schema.nodes[schema.nodes.length - 1].coordinates[0] + 50 : 100,
        schema.nodes.length !== 0 ? schema.nodes[schema.nodes.length - 1].coordinates[1] + 50 : 100,
      ],
      render: CustomNode,
      data: {
        onClick: deleteNode,
        onDeleteButtonClick: deleteNode,
        onSelectFirstButtonClick: onSelectFirst,
        componentData: JSON.parse(JSON.stringify(componentData)),
        handleInput: handleTextfieldInput,
        onNodeTextChange: handleNodeTitleAndDescriptionChanges,
        firstNode: (schema.nodes.length === 0) ? true : false
      },
      inputs: [{ id: uniqid(`port-`), alignment: 'left' }],
      outputs: [{ id: uniqid(`port-`), alignment: 'right' }],
    };
    context.sharedNodes.push(new Array(nextNode));
  }

  // createNewRoom tekee uuden huoneen, ellei sitä jo ole tehtynä. Jos halutaan 
  // tehdä uusi huone vaikka olisi jo olemassa huone, kutsutaan createNewWebSocketProvider functiota.

  function createNewRoom() {
    if (!context.provider) {
      createNewWebSocketProvider(uuid());
    }
  }

  // Tämä ajetaan kun diagrammi muuttuu
  const handleChanges = (e) => {
    createNewRoom();
    let nodesarray = e.nodes;

    if (nodesarray !== undefined) {
      nodesarray.forEach(node => {
        let sharedNode = findNodeFromYArray(context.sharedNodes, node.id)
        if (sharedNode !== undefined) {
          if (node.coordinates[0] !== sharedNode.coordinates[0] || node.coordinates[1] !== sharedNode.coordinates[1]) {
            let index = getNodeIndexFromYArray(context.sharedNodes, sharedNode);
            if (index > -1) {
              replaceItemInYArray(context.sharedNodes, node, index);
            }
          }
        }
      })
      onChange(e);
    }
    if (e.links !== undefined) {
      schema.links = Array.from(e.links);
      onChange(schema);
      // Jos linkkejä diagrammissa on, tässä poistetaan kerralla kaikki linkit jaetusta vektorista ja 
      // lisätään koko linkkivektori siihen. 
      context.sharedLinks.delete(0, context.sharedLinks.length);
      context.sharedLinks.push(e.links);
    }
  }

  const handleTextfieldInput = (nodeID, textFieldID, e) => {
    createNewRoom();
    let yarraynode = findNodeFromYArray(context.sharedNodes, nodeID)
    let arraynode = findNodeFromYArray(schema.nodes, nodeID);
    let index = getNodeIndexFromYArray(context.sharedNodes, yarraynode)
    let textfield = underscore.find(arraynode.data.componentData.dialogform, { id: textFieldID })
    if (e instanceof Date) {
      textfield.data = e.toUTCString();
    } else if (textfield.type === "checkbox") {
      textfield.data = e.target.checked;
    } else {
      textfield.data = e.target.value;
    }
    if (index > -1 && arraynode !== undefined) {
      replaceItemInYArray(context.sharedNodes, arraynode, index);
    } else {
      if (arraynode !== undefined) context.sharedNodes.push(arraynode)
    }
  }

  const handleNodeTitleAndDescriptionChanges = (e, nodeID, type) => {
    createNewRoom();
    const node = schema.nodes.find(node => node.id === nodeID);
    const yarraynode = findNodeFromYArray(context.sharedNodes, nodeID)
    if (type === "title") {
      node.data.componentData.title = e.target.value;
      yarraynode.data.componentData.title = e.target.value;
    } else {
      node.data.componentData.description = e.target.value;
      yarraynode.data.componentData.description = e.target.value;
    }
    let index = getNodeIndexFromYArray(context.sharedNodes, yarraynode)
    if (index > -1 && node !== undefined) {
      replaceItemInYArray(context.sharedNodes, yarraynode, index);
    } else {
      if (node !== undefined) context.sharedNodes.push(node)
    }
    onChange(schema)
  };

  const loadNewSchema = (course) => {

    /*        async function fetchData() {
        //        flushLinks();
        //        flushNodes();
                providerCallback(course.room);
                setTitle(course.name);
                setDescription(course.description);
           //     pushNodesToArray(course.nodes);
           //     course.links.forEach(link => { schema.links.push(link); });
           //     sharedNodes.push(schema.nodes);
           //     sharedLinks.push(course.links);
                sharedDocumentMetaData.set('title', course.name);
                sharedDocumentMetaData.set('description', course.description);
                sharedDocumentMetaData.set('allowed_users', [currentUser]);
            }*/
    lasttime = 0;
    createNewWebSocketProvider(course.room);
    setTitle(course.title);
    setDescription(course.description);
    context.sharedDocumentMetaData.set('title', course.title);
    context.sharedDocumentMetaData.set('description', course.description);
    context.sharedDocumentMetaData.set('allowed_users', new Array(currentUser));
  }

  const createNewCourseClicked = (e) => {
    lasttime = 0;
    setTitle('new course');
    setDescription('course description');
    let room = uuid();
    createNewWebSocketProvider(room);
    context.sharedDocumentMetaData.set('title', title);
    context.sharedDocumentMetaData.set('description', description);
    context.sharedDocumentMetaData.set('allowed_users', new Array(currentUser));
    context.sharedDocumentMetaData.set('room', room);
    flushNodes();
    flushLinks();
    onChange(schema);
  };

  const useStyles = makeStyles({
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingBottom: 5,
      height: 20,
      textAlign: 'center',
    },
    description: {
      paddingTop: 5,
      fontSize: 14,
      paddingBottom: 0,
      height: 35,
      color: 'textSecondary',
      textAlign: 'center',
    },
  });

  const classes = useStyles();

  function onTitleTextChange(e) {
    lasttime = 0;
    createNewRoom();
    context.sharedDocumentMetaData.set('title', e.target.value);
    setTitle(e.target.value);
  }

  function onDescriptionTextChange(e) {
    lasttime = 0;
    createNewRoom();
    context.sharedDocumentMetaData.set('description', e.target.value);
    setDescription(e.target.value);
  }

  function inviteButtonClicked(e) {
    setDialogOpen(true);
  }

  function inviteDialogClosed(e) {
    setDialogOpen(false);
  }

  function onAllowedListChange(newlist) {
    lasttime = 0;
    context.sharedDocumentMetaData ? context.sharedDocumentMetaData.set('allowed_users', newlist) : console.log('sharedDocumentMetadata not set yet.');
  };

  const onSelectFirst = (e, nodeID) => {

    function deselectFirstNode() {
      for (let node of schema.nodes) {
        if (node.data.firstNode === true) {
          node.data.firstNode = false;
        }
      }
    }

    const node = schema.nodes.find(node => node.id === nodeID);
    const yarraynode = findNodeFromYArray(context.sharedNodes, nodeID)
    if (node.data.firstNode) {
      node.data.firstNode = false;
      yarraynode.data.firstNode = false;
    } else {
      deselectFirstNode();
      node.data.firstNode = true;
      yarraynode.data.firstNode = true;
    }
    let index = getNodeIndexFromYArray(context.sharedNodes, yarraynode)
    if (index > -1 && node !== undefined) {
      replaceItemInYArray(context.sharedNodes, yarraynode, index);
    } else {
      if (node !== undefined) context.sharedNodes.push(node)
    }
    onChange(schema)
  }

  function exportSchema(tree) {

    const emptyline = '\n';
    const heading1 = '# ';
    const heading2 = '## ';
    const heading3 = '### ';
    const divider = '\n---\n';
    const linebreak = '\n\\pagebreak\n'
    let exportableText = '';

    const printDialogFormContent = function (dialogData) {
      if (dialogData !== undefined) {
        let text = '';
        for (let part of dialogData) {
          if (part.type === 'radiobutton') {
            text += emptyline + heading3 + part.label + emptyline;
            for (let value of part.values) {
              text += ` - [${part.default === value ? 'x' : ' '}] ` + value + emptyline;
            }
          } else if (part.type === 'checkbox') {
            text += emptyline + heading3 + part.label + emptyline + `[${part.data === 'true' ? 'x' : ' '}] ` + part.data + emptyline;
          } else if (part.data.charAt(0) !== '!') {
            text += emptyline + heading3 + part.label + emptyline + part.data + emptyline;
          }
        }
        return text;
      }
      return;
    }

    const it = tree.preOrderTraversal();
    let result = it.next();
    exportableText = emptyline + heading1 + context.sharedDocumentMetaData.get('title') + emptyline + context.sharedDocumentMetaData.get('description') + emptyline + divider;
    while (!result.done) {
      exportableText += emptyline + heading2 + result.value.value.content + emptyline + result.value.value.data.componentData.description + emptyline;
      let dialogText = printDialogFormContent(result.value.value.data.componentData.dialogform)
      if (dialogText !== undefined) exportableText += dialogText;
      exportableText += linebreak;
      result = it.next();
    }
    if (exportableText !== undefined && exportableText !== '') {
      fetch('/api/v1/pdf', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          exportedText: exportableText,
        })
      })
        .then(res => res.blob())
        .then(function (data) {
          saveAs(data, context.sharedDocumentMetaData.get('title').replace(/\s/g, "").concat('.pdf'));
        })
        .catch(error => {
          console.log('Error while downloading file. ' + error);
        });
    }

  }

  function exportButtonClicked(e) {

    let schemaTree;
    // kloonataan schema array ja lisätään uusi property objekteihin
    let clonedSchema = schema.nodes.map((x) => {
      x.children = [];
      x.exported = false;
      return x;
    });

    let findFirstNode = function () {
      for (let node of schema.nodes) {
        if (node.data.firstNode === true) {
          return node;
        }
      }
    }

    let findNodeWithPort = function (outputPort, inputPort) {
      for (const node of clonedSchema) {
        let input = _.first(node.inputs).id;
        let output = _.first(node.outputs).id;
        if (((output === outputPort || input === inputPort) || (output === inputPort || input === outputPort)) && !node.exported) {
          return node;
        }
      }
      return undefined;
    }

    let buildTree = function (vektori, parent) {
      if (vektori === undefined || vektori.length === 0) return;
      for (const node of vektori) {
        let outputPort = _.first(node.outputs).id;
        let inputPort = _.first(node.inputs).id;
        let linksToChildren = _.filter(schema.links, function (child) { return ((child.output === inputPort || child.input === outputPort) || (child.output === outputPort || child.input === inputPort)) });
        node.exported = true;
        if (schemaTree === undefined) {
          schemaTree = new Tree(node.id, node);
        } else {
          schemaTree.insert(parent !== undefined ? parent.id : undefined, node.id, node);
        }
        // jos solmulla on lapsia, tutkitaan ne seuraavaksi 
        if (!_.isEmpty(linksToChildren)) {
          let children = [];
          for (const link of linksToChildren) {
            let solmu = findNodeWithPort(link.output, link.input);
            if (solmu) {
              children.push(solmu);
              node.children.push(solmu);
            }
          }
          buildTree(node.children, node);
        }
      }
    }
    // Ei tehdä mitään, jos schema on tyhjä
    if (schema.nodes.length > 0 && schema.links.length > 0) {
      // etsitään ensimmäiseksi merkitty solmu schemasta
      let firstone = findFirstNode();
      // muutetaan schema puuksi. 
      if (firstone !== undefined) buildTree(new Array(firstone), undefined);
      // käynnistetään export
      if (schemaTree !== undefined) exportSchema(schemaTree);
    }
  }

  function logoutButtonClicked() {
    axios.post('/logout')
      .then(function (response) {
        console.log('navigate to main page');
        context.userID = '';
        window.location.replace('/');
      })
  }

  return (
    <div className='container'>
      <AppBar position='static'>
        <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#8b5dcb' }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item >
              <div className='barButtonContainer'>
                <Button style={{ marginRight: '15px' }} onClick={createNewCourseClicked} variant='contained' >Create new course</Button>
                <CourseList onExistingCourseClick={loadNewSchema} user={currentUser} />
                <Button style={{ marginRight: '15px' }} onClick={inviteButtonClicked} variant='contained'>Invite</Button>
                <Button style={{ marginRight: '15px' }} onClick={exportButtonClicked} variant='contained'>Export</Button>
              </div>
            </Grid>
            <Grid item >
              <div className='barTextfield'>
                <div>
                  <TextField
                    className={classes.title}
                    InputProps={{ disableUnderline: true, classes: { input: classes.title } }}
                    value={title}
                    onInput={(e) => onTitleTextChange(e)}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    className={classes.description}
                    InputProps={{ disableUnderline: true, classes: { input: classes.description } }}
                    value={description}
                    onInput={(e) => onDescriptionTextChange(e)}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                {context.userID}
              </div>
            </Grid>
            <Grid item >
              <AwarenessList userlist={context.userlist} />
            </Grid>
            <Grid item>
              <div className='loginBarButtonContainer'>
                <Button style={{ marginRight: '55px', width: '80px' }} onClick={logoutButtonClicked} variant='contained'>
                  Logout
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} style={{ padding: '15px' }}>
        <Grid item xs={12} md={3}>
          <CoursePalette onClickEvent={addNewNode} />
        </Grid>
        <Divider />
        <Grid item xs={'auto'} className='wrapper'>
          <Diagram schema={schema} onChange={handleChanges} />
        </Grid>
      </Grid>
      <InviteDialog
        open={dialogOpen}
        onClose={inviteDialogClosed}
        userList={context.sharedDocumentMetaData ? context.sharedDocumentMetaData.get('allowed_users') : []}
        listChanged={() => onAllowedListChange}
      />
    </div>
  );


};

<App />

export default App;
