import React, { useState } from 'react';
import axios from 'axios';
import { Button, Menu, MenuItem } from '@material-ui/core';

export default function CourseList(props) {

  const [courselist, setCourselist] = useState([]);
  /*
    useEffect(() => {
      /*  const fetchData = async () => {
           const result = await axios('/api/v1/courselist/'+  props.user);
           console.log(result);
           setCourselist(result.data);
       }
       fetchData(); */
  /*}, [])
  */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    const fetchData = async () => {
      const result = await axios('/api/v1/courselist/' + props.user);
      let temporary = JSON.stringify(result.data)
        .replace(/\\/g, "")
        .replace(/}"."{/g, "},{")
        .replace(/\["{/g, "[{")
        .replace(/}"\]/g, "}]");
      setCourselist(JSON.parse(temporary));
    }
    fetchData();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        style={{ marginRight: '15px' }}>
        Load course
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {courselist.map((course) =>
          <MenuItem key={course.room} onClick={() => {
            props.onExistingCourseClick(course);
            handleClose();
          }}>
            {course.title}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

