import React, { useContext } from 'react'
import { LoginButton } from './LoginButton'
import ostContext from './ostContext';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

export default function Login(props) {

  const context = useContext(ostContext);
  //const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const loginButtonClicked = async (e) => {
    console.log(context.userID)
    /*    const config = {
          headers: {
            "Access-Control-Allow-Origin": "https://sso.test.utu.fi, https://ostdev.utu.fi:1234",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            "Access-Control-Allow-Credentials": 'true',
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
          //  origin: "https://sso.test.utu.fi, https://ostdev.utu.fi:1234, https://ostdev.utu.fi",
        }
        const params = {
          data: undefined
        }*/
    fetch("https://ostdev.utu.fi:1234/login", {
      method: 'GET',
      /*      headers: {
              "Access-Control-Allow-Origin": "https://sso.test.utu.fi, https://ostdev.utu.fi:1234",
              "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
              "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Headers, Access-Control-Allow-Credentials",
              "Access-Control-Allow-Credentials": 'true',
              "Accept": "application/json",
              "Content-Type": "application/json",
            },*/
      credentials: 'include',
    })
      .then((res) => {
        console.log('Autentikoitunut, ehkä...')
        const token = searchParams.get('token')
        console.log(token)
        localStorage.setItem('token', token)
        if (res.data.userID === undefined) {
          console.log('Ei käyttäjää')
          redirectToLogin()
        }
      })
      .catch((err) => {
        console.error(err, "Login failed.");
        context.loginStatus = false;
        redirectToLogin()
      });

    /*
    
        await axios.get("https://ostdev.utu.fi:1234/login")
          .then((res) => {
            console.log('Autentikoitunut, ehkä...')
            const token = searchParams.get('token')
            console.log(token)
            localStorage.setItem('token', token)
            if (res.data.userID === undefined) {
              console.log('Ei käyttäjää')
              redirectToLogin()
            }
    
          })
          .catch((err) => {
            console.error(err, "Login failed.");
            context.loginStatus = false;
            redirectToLogin()
          });
    */
    function redirectToLogin() {
      //      navigate('/login');
      window.location.replace("/login");
    };
  }

  return (
    <LoginButton buttonClicked={loginButtonClicked} />
  )
}
