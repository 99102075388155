import React, { useState } from 'react';
import { FormGroup, Label } from 'beautiful-react-ui';
import { TextareaAutosize } from '@material-ui/core';
import useStyles from './DialogformComponentStyles';

export const TextareaComponent = ({ modelelement, handleInput, nodeID, avain }) => {

  const [value, setValue] = useState(modelelement.data)
  /*
    useEffect(() => { 
    //	setValue(modelelement.data)
    }, [modelelement.data])
  */
  const classes = useStyles();

  return (
    <li key={avain} style={{ padding: "10px" }}>
      <FormGroup key={avain + 2000} orientation="vertical">
        <Label key={avain + 2001} htmlFor="field-id" className={classes.root}>{modelelement.label}</Label>
        <TextareaAutosize
          key={avain + 2002}
          minRows={4}
          maxRows={30}
          placeholder=""
          onChange={(e) => setValue(e.target.value)}
          onInput={(e) => handleInput(nodeID, modelelement.id, e)}
          value={value}
          style={{ minWidth: '450px', maxWidth: '520px' }}
        />
      </FormGroup>
    </li>
  )
}

