import React, { useState } from 'react';
import { FormGroup, Label } from 'beautiful-react-ui';
import { TextField } from '@material-ui/core';
import useStyles from './DialogformComponentStyles';

export const TextfieldComponent = ({ modelelement, handleInput, nodeID, avain }) => {

  const [value, setValue] = useState(modelelement.data)
  /*
    useEffect (() =>{ 
    //	setValue(modelelement.data)
    }, [modelelement.data])
  */
  const classes = useStyles();

  return (
    <li key={avain} style={{ padding: "10px" }}>
      <FormGroup key={avain + 1002} orientation="vertical">
        <Label key={avain + 1001} htmlFor="field-id" className={classes.root}>{modelelement.label}</Label>
        <TextField
          key={avain + 1000}
          style={{ width: '100%' }}
          type="text"
          id={modelelement.id}
          onChange={(e) => setValue(e.target.value)}
          onInput={(e) => handleInput(nodeID, modelelement.id, e)}
          value={value}
          variant="outlined"
        />
      </FormGroup>
    </li>
  )
}