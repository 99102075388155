import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ostContext from './components/ostContext';
//import { Router } from './components/Router';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import NoPage from './pages/NoPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import App from './App';

let provider;
let sharedNodes;
let sharedLinks;
let sharedDocumentMetaData;
let awareness;
let userlist = [];
let doc1; //doc1 = lokaaliversio dokumentista, tähän doc1 synkroidaan ja tehdään muutokset lokaalisti.
let doc2; //doc2 = verkkoversio dokumentista
let userID;
let loginStatus = false;

ReactDOM.render(
  <ostContext.Provider value={{
    provider,
    sharedNodes,
    sharedLinks,
    sharedDocumentMetaData,
    awareness,
    userlist,
    doc1,
    doc2,
    userID,
    loginStatus
  }}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute />} />
          <Route path="/login" element={<ProtectedRoute />} />
          <Route path="/loggedin" element={<ProtectedRoute />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/ui" element={<App />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ostContext.Provider >,
  document.getElementById('root'),
);
